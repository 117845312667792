import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { createStore, StoreProvider } from 'easy-peasy'
import './utils/i18n'
import theme from './theme'
import './css/index.css'
import App from './App'
import Store from './store'
import { WebStorageStateStore } from 'oidc-client-ts'
import { AuthProvider, } from 'react-oidc-context'

const store = createStore(Store)

const root = ReactDOM.createRoot(document.getElementById('root'))

const oidcConfig = {
  authority: window.__RUNTIME_CONFIG__.KC_URL,
  client_id: window.__RUNTIME_CONFIG__.KC_CLIENT_ID,
  redirect_uri: window.__RUNTIME_CONFIG__.HOST_URL,
  scope: window.__RUNTIME_CONFIG__.KC_SCOPE,
  automaticSilentRenew: true,
  monitorSession: false,
  checkSessionIntervalInSeconds: 60,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback: () => {
    console.log('onSigninCallback')
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

root.render(
  <AuthProvider {...oidcConfig}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StoreProvider store={store}>

          <App />

        </StoreProvider>
      </ThemeProvider>
    </BrowserRouter>
  </AuthProvider>

);


// reportWebVitals();
