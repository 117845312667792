import AddIcon from '@mui/icons-material/Add'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import DownloadIcon from '@mui/icons-material/Download'
import { Table } from '@mui/material'
import { TableHead } from '@mui/material'
import { TableContainer } from '@mui/material'
import { TableRow } from '@mui/material'
import { TableBody } from '@mui/material'
import { Container } from '@mui/material'
import { Paper } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { TableCell } from '@mui/material'
import { Typography } from '@mui/material'
import { useStoreState } from 'easy-peasy'
import { useStoreActions } from 'easy-peasy'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Fab } from '@mui/material'
import CarForm from '../cars/CarForm'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ViewIcon from '@mui/icons-material/Visibility'
import HistoryIcon from '@mui/icons-material/History'
import { Dialog } from '@mui/material'
import { DialogContent } from '@mui/material'
import { DialogContentText } from '@mui/material'
import { DialogTitle } from '@mui/material'
import { DialogActions } from '@mui/material'
import { Button } from '@mui/material'
import Breadcrumbler from '../breadcrumbs/Breadcrumbler'
import { Grid } from '@mui/material'
import QrScannerTextField from '../../utils/formfields/QrScannerTextField'
import CarSpotView from './CarSpotView'
import { DropzoneArea } from 'mui-file-dropzone'
import { Link } from '@mui/material'
import QrPreview from '../qrpreview/QrPreview'
import { Box } from '@mui/material'
import { FormGroup } from '@mui/material'
import { FormControlLabel } from '@mui/material'
import { Checkbox } from '@mui/material'
import Icon from '@svgr-iconkit/material-community'
import { Pagination } from '@mui/material'
import { Alert } from '@mui/material'
import CsvDownloader from 'react-csv-downloader'

const Cars = () => {
    const { t } = useTranslation()
    const itemsPerPage = 10

    const [isUpdating, setIsUpdating] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [deleteDialogErrorMessage, setDeleteDialogErrorMessage] = useState('')
    const [csvUploadDialogErrorMessage, setCsvUploadDialogErrorMessage] = useState('')
    const [isUploading, setIsUploading] = useState(false)
    const [isExportingCsv, setIsExportingCsv] = useState(false)
    const [showCarForm, setShowCarForm] = useState(false)
    const [showCsvUploadDialog, setShowCsvUploadDialog] = useState(false)
    const [showDeleteCarDialog, setShowDeleteCarDialog] = useState(false)
    const [showViewSpotDialog, setShowViewSpotDialog] = useState(false)
    const [targetCar, setTargetCar] = useState(undefined)
    const [lastUploadedFile, setLastUploadedFile] = useState(undefined)
    const [fetchDeletedCars, setFetchDeletedCars] = useState(false)
    const [fetchExternalCarsOnly, setFetchExternalCarsOnly] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [pageNr, setPageNr] = useState(1)

    const cars = useStoreState(state => state.cars)
    const getCarsAction = useStoreActions(actions => actions.getCars)
    const getCarparksAction = useStoreActions(actions => actions.getCarparks)
    const deleteCarAction = useStoreActions(actions => actions.deleteCar)
    const carCsvUploadAction = useStoreActions(actions => actions.carCsvUpload)

    const fetchData = useCallback(async (q = '', getDeletedCars = false, getExternalCars = false, pageNr = 0, itemsPerPage = 25) => {
        setIsUpdating(true)
        await getCarsAction({ search: q, fetchDeletedCars: getDeletedCars, fetchExternalCarsOnly: getExternalCars, page: pageNr, limit: itemsPerPage })
        setIsUpdating(false)
    }, [getCarsAction])

    useEffect(() => {
        fetchData(pageNr - 1, itemsPerPage)
        // eslint-disable-next-line
    }, [])

    const onPaginationChange = (e, newPageNr) => {
        setPageNr(newPageNr)
        fetchData(searchTerm, fetchDeletedCars, fetchExternalCarsOnly, newPageNr - 1, itemsPerPage)
    }

    const deleteCar = async () => {
        if (!((targetCar && targetCar._id))) {
            throw new Error("targetCar is invalid!")
        }

        setIsDeleting(true)
        const result = await deleteCarAction(targetCar._id)


        if (result.error && result.error.response && result.error.response.status === 400 && result.error.response.data && result.error.response.data.code === "12000") {
            setDeleteDialogErrorMessage(t('Car cannot be deleted because it is used in an open task!'))
            setIsDeleting(false)
            return false
        }


        setShowDeleteCarDialog(false)
        setTargetCar(undefined)
        setIsDeleting(false)
        fetchData(searchTerm, fetchDeletedCars, fetchExternalCarsOnly, pageNr - 1, itemsPerPage)
    }

    const searchFieldOnChange = (e) => {
        setSearchTerm(e.target.value)
        fetchData(e.target.value, fetchDeletedCars, fetchExternalCarsOnly, pageNr - 1, itemsPerPage)
    }

    const fetchDeletedCarsOnChange = (e) => {
        setFetchDeletedCars(e.target.checked)
        fetchData(searchTerm, e.target.checked, fetchExternalCarsOnly, pageNr - 1, itemsPerPage)
    }
    const setFetchExternalCarsOnlyOnChange = (e) => {
        console.log("setFetchExternalCarsOnlyOnChange", e.target.checked)
        setFetchExternalCarsOnly(e.target.checked)
        setPageNr(1)
        fetchData(searchTerm, fetchDeletedCars, e.target.checked, 0, itemsPerPage)
    }

    const handleCsvUpload = async (files) => {
        setCsvUploadDialogErrorMessage('')
        setLastUploadedFile(Array.isArray(files) && files[0] ? files[0] : undefined)
    }

    const handleCsvExport = async () => {
        console.log("handleCsvExport")
        if (!cars.data || isExportingCsv) {
            return false
        }
        setIsExportingCsv(true)

        const carparks = await getCarparksAction()
        const mapCarparkIdsToCarparks = []
        const mapDeckIdsToDecks = []
        carparks.forEach(carpark => {
            mapCarparkIdsToCarparks[carpark._id] = carpark
            if (!carpark.decks) {
                return false
            }
            carpark.decks.forEach(deck => {
                mapDeckIdsToDecks[deck._id] = deck
            })
        })

        const dataForExport = cars.data.map((item) => {
            let carpark = undefined
            let deck = undefined
            if (item.spot && item.spot.carpark) {
                carpark = mapCarparkIdsToCarparks[item.spot.carpark]
            }
            if (item.spot && item.spot.deck) {
                deck = mapDeckIdsToDecks[item.spot.deck]
            }
            return {
                Name: item.name,
                Type: item.type,
                Numberplate: item.numberPlate,
                Customername: item.customerName,
                VIN: item.vin,
                QR: item.qr,
                Deleted: item.deleted,
                CreatedAt: moment(item.createdAt).format(t("date.format") + " HH:mm"),
                UpdatedAt: moment(item.updatedAt).format(t("date.format") + " HH:mm"),
                SpotCarpark: carpark ? carpark.name : '',
                SpotDeck: deck ? deck.name : '',
                SpotName: item.spot && item.spot.name ? item.spot.name : '',
                SpotQr: item.spot && item.spot.qr ? item.spot.qr : '',
                KeylockerName: item.keyhook && item.keyhook.keylocker && item.keyhook.keylocker.name ? item.keyhook.keylocker.name : '',
                KeylockerQr: item.keyhook && item.keyhook.keylocker && item.keyhook.keylocker.qr ? item.keyhook.keylocker.qr : '',
                KeylockerHookNr: item.keyhook && item.keyhook.nr ? item.keyhook.nr : '',
            }
        })
        setIsExportingCsv(false)
        return dataForExport
    }

    const sendLastUploadedFileToServer = async () => {
        if (!lastUploadedFile) {
            return false
        }

        setIsUploading(true)
        const result = await carCsvUploadAction(lastUploadedFile)

        if (result.error && result.error.response && result.error.response.status === 400 && result.error.response.data && result.error.response.data.message) {
            let message = result.error.response.data.message
            if (message.includes("Car validation failed: vin: Validator failed for path `vin` with value")) {
                const vin = message.substring(72, message.length - 1)
                message = t("csvUpload.error.InvalidVin").replace("%VIN%", vin)
            }
            setCsvUploadDialogErrorMessage(t(message))
            setLastUploadedFile(undefined)
            setIsUploading(false)
            return false
        }

        setIsUploading(false)
        setShowCsvUploadDialog(false)
        fetchData(searchTerm, fetchDeletedCars, fetchExternalCarsOnly, pageNr - 1, itemsPerPage)
        return false
    }

    return (
        <>
            <Breadcrumbler levels={[{ title: t("Cars"), url: '/cars' }]} />

            <Typography variant="h1" component="div" gutterBottom>
                {t("Cars")}
            </Typography>

            <Grid container spacing={2} sx={{ mb: '1rem' }}>
                <Grid item xs={12} sm={12} lg={4}>
                    <QrScannerTextField
                        label="Suche"
                        id="outlined-size-small"
                        size="small"
                        fullWidth={true}
                        placeholder="Suche nach Fahrzeugbeschreibung, Typ, Kennzeichen, VIN oder QR-Code ..."
                        focused
                        onChange={searchFieldOnChange}
                    ></QrScannerTextField>
                </Grid>
                <Grid item xs={8} sm={8} lg={3}>
                    <FormGroup sx={{ flexDirection: 'row' }}>
                        <FormControlLabel control={<Checkbox onClick={(e) => (fetchDeletedCarsOnChange(e))} checked={fetchDeletedCars} />} label="Gelöschte Fahrzeuge anzeigen" />
                    </FormGroup>
                </Grid>
                <Grid item xs={8} sm={8} lg={3}>
                    <FormGroup sx={{ flexDirection: 'row' }}>
                        <FormControlLabel control={<Checkbox onClick={(e) => (setFetchExternalCarsOnlyOnChange(e))} checked={fetchExternalCarsOnly} />} label="Nur externe Fahrzeuge anzeigen" />
                    </FormGroup>
                </Grid>
                <Grid item xs={4} sm={4} lg={2} textAlign='right'>
                    <CsvDownloader
                        filename="rcm-fahrzeuge"
                        suffix={moment().format("YYYY-MM-DD-HH[h]mm[m]ss[s]")}
                        extension=".csv"
                        separator=";"
                        wrapColumnChar=""
                        style={{ display: 'inline-block' }}
                        datas={handleCsvExport}
                    >
                        <Fab color="primary" size="small" title={t("CSV-Export")}>
                            {isExportingCsv &&
                                <Box align="center" style={{ padding: '5px 0 0 0' }}>
                                    <CircularProgress size={200} color='inherit' />
                                </Box>
                            }
                            {!isExportingCsv &&
                                <DownloadIcon />
                            }
                        </Fab>
                    </CsvDownloader>

                    <Fab sx={{ ml: '1rem' }} color="info" size="small" title={t("CSV-Upload")} onClick={() => { setShowCsvUploadDialog(true) }} >
                        <UploadFileIcon />
                    </Fab>
                    <Fab sx={{ ml: '1rem' }} color="primary" size="small" title={t("Add Car")} onClick={() => { setShowCarForm(true) }} >
                        <AddIcon />
                    </Fab>
                </Grid>
            </Grid>

            {
                isUpdating &&
                <Container align="center">
                    <CircularProgress />
                </Container>
            }

            {
                !isUpdating && cars && cars.data && Array.isArray(cars.data) && cars.data.length === 0 &&
                <Box sx={{ pb: '2rem', pt: '2rem' }}>
                    Keine Fahrzeuge gefunden
                </Box>
            }

            {
                !isUpdating && cars && cars.data && Array.isArray(cars.data) && cars.data.length > 0 &&
                <>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>{t('Name')}</TableCell>
                                    <TableCell>{t('Type')}</TableCell>
                                    <TableCell align='center'>{t('Extern')}</TableCell>
                                    <TableCell>{t('Numberplate')}</TableCell>
                                    <TableCell>{t('VIN')}</TableCell>
                                    <TableCell>{t('Customer')}</TableCell>
                                    <TableCell>{t('QR-Code')}</TableCell>
                                    <TableCell align="center">{t('Spot')}</TableCell>
                                    <TableCell align="center">{t('Keylocker')}</TableCell>
                                    <TableCell align="right">{t('Created at')}</TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cars.data.map((car, index) => (
                                    <TableRow
                                        key={car._id}
                                        sx={{
                                            verticalAlign: 'top',
                                            '&:last-child td, &:last-child th': { border: 0 }
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {((pageNr - 1) * itemsPerPage) + index + 1}
                                        </TableCell>
                                        <TableCell>
                                            {car.name}
                                        </TableCell>
                                        <TableCell>
                                            {car.type}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {car.state === 'extern' && <Icon name={'road-variant'} style={{ color: '#313639', width: '1.5rem', marginRight: '0.5rem' }} />}
                                        </TableCell>
                                        <TableCell>
                                            {car.numberPlate}
                                        </TableCell>
                                        <TableCell>
                                            {car.vin}
                                        </TableCell>
                                        <TableCell>
                                            {car.customerName}
                                        </TableCell>
                                        <TableCell>
                                            <QrPreview item={car}>
                                                {car.qr}
                                            </QrPreview>
                                        </TableCell>
                                        <TableCell align="center">
                                            {car.spot &&
                                                <IconButton onClick={() => { console.log("setShowViewSpotDialog", car.spot); setTargetCar(car); setShowViewSpotDialog(true) }} aria-label="view" title={t('View Task')}>
                                                    <ViewIcon />
                                                </IconButton>
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            {car.keyhook &&
                                                <>
                                                    {car.keyhook.keylocker.name} {car.keyhook.nr ? '#' + car.keyhook.nr : ''}
                                                </>
                                            }
                                        </TableCell>
                                        <TableCell align="right">{moment(car.createdAt).format(t("date.format") + " HH:mm")}</TableCell>
                                        <TableCell align="right" style={{ whiteSpace: 'pre' }}>
                                            <IconButton onClick={() => { setTargetCar(car); setShowCarForm(true) }} aria-label="delete" title={t('Edit Car')} disabled={fetchDeletedCars}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => { setTargetCar(car); setShowDeleteCarDialog(true) }} aria-label="delete" title={t('Delete Car')} disabled={fetchDeletedCars}>
                                                <DeleteIcon />
                                            </IconButton>
                                            <IconButton href={`/carHistory/${car._id}`} aria-label="delete" title={t('Car History')}>
                                                <HistoryIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box display='flex' justifyContent='center' sx={{ pt: '1rem' }}>
                        <Pagination onChange={onPaginationChange} page={pageNr} count={(cars && cars.pagination && cars.pagination.total) ? (Math.floor(cars.pagination.total / itemsPerPage) + 1) : 1} siblingCount={1} boundaryCount={1} showFirstButton showLastButton />
                    </Box>
                </>
            }

            <Dialog
                open={showDeleteCarDialog}
                onClose={() => { alert('Close') }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Delete Car')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('Do you really want to delete this Car?')}<br />
                        <br />
                        {targetCar && `${t('Name')}:  ${targetCar.name ? targetCar.name : '---'}`}<br />
                        {targetCar && `${t('Numberplate')}: ${targetCar.numberPlate ? targetCar.numberPlate : '---'}`}<br />
                        {targetCar && `${t('QR-Code')}: ${targetCar.qr ? targetCar.qr : '---'}`}<br />
                        {targetCar && `${t('Type')}: ${targetCar.type ? targetCar.type : '---'}`}<br />
                        {targetCar && `${t('VIN')}: ${targetCar.vin ? targetCar.vin : '---'}`}<br />
                        <br />
                    </DialogContentText>
                    {deleteDialogErrorMessage &&
                        <Alert severity="warning">{deleteDialogErrorMessage}</Alert>
                    }
                    {isDeleting &&
                        <Container align="center">
                            <CircularProgress />
                        </Container>
                    }
                </DialogContent>
                <DialogActions sx={{ mb: '1rem', p: '6px 24px' }}>
                    <Button onClick={() => { setShowDeleteCarDialog(false); setTargetCar(undefined) }} autoFocus disabled={isDeleting} variant="outlined">{t('Cancel')}</Button>
                    <Button onClick={() => { deleteCar() }} variant="contained" color="error" disabled={isDeleting}>{t('Delete Car')}</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showViewSpotDialog}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Fahrzeugstandort')}
                </DialogTitle>
                <DialogContent>
                    {showViewSpotDialog && <CarSpotView car={targetCar} />}
                </DialogContent>
                <DialogActions sx={{ mb: '1rem', p: '6px 24px' }}>
                    <Button onClick={() => { setShowViewSpotDialog(false); setTargetCar(undefined) }} autoFocus variant="contained">{t('Close')}</Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={showCsvUploadDialog}
                onClose={() => { setShowCsvUploadDialog(false) }}
            >
                <DialogTitle id="alert-dialog-title">
                    {t('CSV-Upload')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Beispieldatei: <Link target="_blank" href="/files/example/cars.csv" download="cars.csv">[cars.csv]</Link><br />
                        <br />
                    </DialogContentText>
                    <DropzoneArea
                        dropzoneClass={'csvDropzoneArea'}
                        onChange={handleCsvUpload}
                        // acceptedFiles={['text/csv', 'text/plain', 'application/vnd.ms-excel', 'text/x-csv', 'application/csv', 'application/x-csv']}
                        filesLimit={1}
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        useChipsForPreview={true}
                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                        previewChipProps={{
                            style: {
                                minWidth: 210,
                                maxWidth: 300
                            }
                        }}
                        maxFileSize={30000000}
                        previewText="Datei:"
                        dropzoneText={t('Hier klicken oder Datei hier fallen lassen')}
                        showAlerts={false}
                    />
                    {csvUploadDialogErrorMessage &&
                        <Alert severity="error" style={{ marginTop: '0.5rem' }}>{csvUploadDialogErrorMessage}</Alert>
                    }
                    {isUploading &&
                        <Container align="center">
                            <CircularProgress />
                        </Container>
                    }
                </DialogContent>
                <DialogActions sx={{ mb: '1rem', p: '6px 24px' }}>
                    <Button onClick={() => { setShowCsvUploadDialog(false); setLastUploadedFile(undefined); setCsvUploadDialogErrorMessage(''); }} variant="outlined" autoFocus disabled={isUploading}>{t('form.Cancel')}</Button>
                    <Button onClick={() => { sendLastUploadedFileToServer() }} variant="contained" disabled={isUploading || !lastUploadedFile}>{t('form.Submit')}</Button>
                </DialogActions>
            </Dialog>

            {
                !isUpdating && showCarForm &&
                <CarForm initialValues={targetCar} closeDialog={() => { setTargetCar(undefined); setShowCarForm(false); fetchData() }} />
            }
        </>
    )
}
export default Cars
