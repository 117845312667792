import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff'
import Joi from 'joi'
import { useStoreActions } from 'easy-peasy'
import { Alert } from '@mui/material'
import QrScannerTextFieldMuiRff from '../../utils/formfields/QrScannerTextFieldMuiRff'
import { Field } from 'react-final-form'
import { useState } from 'react'

const CarForm = (props) => {
    const { t } = useTranslation()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const getCarsWithStrictEqualityAction = useStoreActions(actions => actions.getCarsWithStrictEquality)
    const createCar = useStoreActions(actions => actions.createCar)
    const resetCar = useStoreActions(actions => actions.resetCar)
    const updateCar = useStoreActions(actions => actions.updateCar)

    const onSubmit = async (values) => {
        console.log("CarForm onSubmit", values)
        setErrorMessage(undefined)
        setIsSubmitting(true)
        await resetCar()

        // warn if there are duplicates, but allow saving anyway if user insists
        const dups = await findDuplicates(values)
        let dupMessage = ''
        if(dups.numberPlate > 0) {
            dupMessage += "Es ist bereits ein Fahrzeug mit dem Kennzeichen hinterlegt.\n\n"
        }
        if(dups.vin > 0) {
            dupMessage += "Es ist bereits ein Fahrzeug mit dieser VIN hinterlegt.\n\n"
        }
        if(dupMessage.length > 0) {
            dupMessage += "Möchten Sie trotzdem speichern?"
            if(!window.confirm(dupMessage)) {
                setIsSubmitting(false)
                return false
            }
        }

        // save
        let result = undefined
        if (props.initialValues && props.initialValues._id) {
            const valuesCopy = JSON.parse(JSON.stringify(values))
            valuesCopy._id = props.initialValues._id
            result = await updateCar(valuesCopy)
        } else {
            result = await createCar(values)
        }

        
        setIsSubmitting(false)
        if (result.error && result.error.response && result.error.response.status === 400 && result.error.response.data && result.error.response.data.code === "11000") {
            setErrorMessage(t('A car with that QR code already exists!'))
            return false
        }

        props.closeDialog(result)
    }

    /* 
        Does a DB lookup for cars with the same VIN or numberPlate.
        We'll allow duplicates, but we warn first.
     */
    const findDuplicates = async (values) => {
        console.log("CarForm findDuplicates", values)

        let resultsNumberplate = []
        if(values.numberPlate) {
            resultsNumberplate = await getCarsWithStrictEqualityAction({ findOnlyCarsWithAnIdNotEqualTo:props && props.initialValues && props.initialValues._id ? props.initialValues._id : undefined, numberPlate: values.numberPlate })
        }
        let resultsVin = []
        if(values.vin) {
            resultsVin = await getCarsWithStrictEqualityAction({ findOnlyCarsWithAnIdNotEqualTo:props && props.initialValues && props.initialValues._id ? props.initialValues._id : undefined, vin: values.vin })
        }
        
        return {
            vin:resultsVin.length,
            numberPlate:resultsNumberplate.length
        }
    }

    const cancel = async () => {
        setIsSubmitting(false)
        props.closeDialog(undefined)
    }

    const validationSchema = Joi.object({
        name: Joi.string().min(1).max(64),
        type: Joi.string().min(1).max(64),
        numberPlate: Joi.string().min(3).max(17),
        vin: Joi.string().min(3).max(17),
        customerName: Joi.string().min(1).max(64),
        qr: Joi.string().min(10).max(10).required(),
    }).or('name', 'type', 'numberPlate', 'vin')

    const validate = async (values) => {
        const validationResult = validationSchema.validate(values, { abortEarly: false })
        if (validationResult.error && Array.isArray(validationResult.error.details)) {
            setIsSubmitting(false)
            const errorMessages = {}
            validationResult.error.details.forEach((errorDetail) => {
                // In case the validationSchema.or('name', 'type', 'numberPlate', 'vin') is not met, "errorDetail.context.key" is undefined.
                // That's why we replace set it once to all four keys so this error-message appears below all of the affected fields in the frontend.
                if (errorDetail.context.key) {
                    errorMessages[errorDetail.context.key] = t(`formError.${errorDetail.message}`)
                } else {
                    errorMessages['name'] = t(`formError.${errorDetail.message}`)
                    errorMessages['type'] = t(`formError.${errorDetail.message}`)
                    errorMessages['numberPlate'] = t(`formError.${errorDetail.message}`)
                    errorMessages['vin'] = t(`formError.${errorDetail.message}`)
                }
            })
            console.log("validationResult.error.details", validationResult.error.details, errorMessages)
            return errorMessages
        }
        return {} // Return {} or undefined when the values are valid, or an Object of validation errors when the values are invalid.
    }


    const getInitialValues = () => {
        const values = {}
        if (props.initialValues && props.initialValues._id) {
            values.name = props.initialValues.name
            values.type = props.initialValues.type
            values.numberPlate = props.initialValues.numberPlate
            values.vin = props.initialValues.vin
            values.customerName = props.initialValues.customerName
            values.qr = props.initialValues.qr
        }
        console.log("getInitialValues", props.initialValues, values)
        return values
    }


    return (
        <>
            <Dialog open={true}>
                <Form
                    onSubmit={onSubmit}
                    initialValues={getInitialValues()}
                    validate={validate}
                    render={({ form, handleSubmit, values }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <DialogTitle>{props.initialValues && props.initialValues._id ? t('Edit Car') : t('Add Car')}</DialogTitle>
                            <DialogContent>
                                <TextField sx={{ mb: '1.5rem' }} label={t('Car description')} name="name" />
                                <TextField sx={{ mb: '1.5rem' }} label={t('Type')} name="type" />
                                <TextField sx={{ mb: '1.5rem' }} label={t('Numberplate')} name="numberPlate" />
                                <TextField sx={{ mb: '1.5rem' }} label={t('VIN')} name="vin" />
                                <TextField sx={{ mb: '1.5rem' }} label={t('Customer')} name="customerName" />
                                <Field name="qr" component={QrScannerTextFieldMuiRff} sx={{ mb: '1.5rem' }} label={t('QR-Code')} required={true} />
                                {errorMessage &&
                                    <Alert severity="warning">{errorMessage}</Alert>
                                }
                            </DialogContent>
                            <DialogActions sx={{ mb: '1rem' }} >
                                <Button onClick={() => { cancel() }} variant="outlined">{t('Cancel')}</Button>
                                <Button type="submit" disabled={isSubmitting} variant="contained">{t('form.Submit')}</Button>
                            </DialogActions>
                        </form>
                    )}
                />
            </Dialog>
        </>
    )
}

export default CarForm